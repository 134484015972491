<template>
	<div class="importPersonData">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="省：">
					<el-select v-model="formInline.provinceDictionaryItem" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select v-model="formInline.cityDictionaryItem" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select v-model="formInline.distinguish" placeholder="请选择区县" @change="changeEconomize($event, 3)">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="乡镇：">
					<el-select v-model="formInline.townCode" placeholder="请选择" @change="changeEconomize($event, 4)">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="村：">
					<el-select v-model="formInline.villageCode" placeholder="请选择" @change="changeEconomize($event, 5)">
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="getDataInfo" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="container">
			<div class="importPersonData-title">
				<div class="importPersonData-title-content clearfix">
					<div class="institutionTotal float-l">
						<img src="@/assets/imgs/institution.png" alt="" />
						<div class="institutionTotal-content">
							<p>转诊业务数</p>
							<p>{{ datas.referralBusiness || 0 }}</p>
						</div>
					</div>
					<div class="institutionTotal float-l mr0">
						<img src="@/assets/imgs/peoples.png" alt="" />
						<div class="institutionTotal-content">
							<p>接诊业务数</p>
							<p>{{ datas.consultationBusiness || 0 }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="content clearfix">
				<div class="chartsClass float-l" ref="importPersonHeight">
					<p>
						<img src="@/assets/imgs/datas.png" alt="" />
						转诊疾病数据统计
					</p>
					<div
						class="nullData"
						:style="{ width: '100%', height: importPersonHeight + 'px', lineHeight: importPersonHeight + 'px' }"
						v-if="datas.referralSuccessRateTotal == 0"
					>
						暂无数据
					</div>
					<div id="importPerson" :style="{ width: '100%', height: importPersonHeight + 'px' }" v-show="datas.referralSuccessRateTotal != 0"></div>
				</div>
				<div class="chartsClass float-l" ref="postChartsHeight">
					<p>
						<img src="@/assets/imgs/datas.png" alt="" />
						转诊成功率
					</p>
					<div :style="{ height: postChartsHeight + 'px' }" class="clearfix">
						<div class="float-l chineseMedical">
							<div id="waterCharts1" :style="{ width: '100%', height: postChartsHeight + 'px' }"></div>
						</div>
						<div class="legendClass float-l" :style="{ marginTop: postChartsHeight / 2 - 52 + 'px' }">
							<div class="legendClass-content">
								<p class="legendClass-title">
									<span></span>
									转诊总人数
								</p>
								<p>{{ (datas.referralData && datas.referralData.referralSize) || 0 }}<span>人</span></p>
							</div>
							<div class="legendClass-content">
								<p class="legendClass-title">
									<span></span>
									转诊成功人数
								</p>
								<p>{{ (datas.referralData && datas.referralData.consultationSize) || 0 }} <span>人</span></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'echarts-liquidfill';
import * as echarts from 'echarts';
export default {
	name: 'receiveData',
	data() {
		return {
			formInline: {
				provinceDictionaryItem: null,
				cityDictionaryItem: null,
				distinguish: null,
				townCode: null,
				villageCode: null,
			},
			regionCode: null,
			townList: [], //乡镇
			villageList: [], //村
			districtList: [], //区县
			marketList: [], //市
			economizeList: [], //省
			importPersonHeight: 318, // 转诊疾病数据统计
			postChartsHeight: 318, // 中医药普及人数统计
			datas: {},
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.importPersonHeight = this.$refs.importPersonHeight.offsetHeight - 90 > 318 ? this.$refs.importPersonHeight.offsetHeight - 90 : 318;
			this.postChartsHeight = this.$refs.postChartsHeight.offsetHeight - 90 > 318 ? this.$refs.postChartsHeight.offsetHeight - 90 : 318;
		});
		this.getEconomizeList();
		this.getDataInfo();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api.getChinaRegions)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			this.regionCode = data;
			switch (type) {
				case 1:
					this.formInline.cityDictionaryItem = null;
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 获取列表
		getDataInfo() {
			let regionCode = this.formInline.villageCode ? this.formInline.villageCode : this.regionCode;
			this.$http
				.get(this.api.getReferralConsultation, { params: { regionCode } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.datas = res.data.collection[0] || {};
						if (this.datas.referralData) {
							this.getPostChartsData(this.datas?.referralRatio);
						}
						if (JSON.stringify(this.datas.referralSuccessRate) != '{}') {
							this.getimportPersonData(this.datas.referralSuccessRate);
						} else {
							this.datas.referralSuccessRateTotal = 0;
						}
					}
				})
				.catch((e) => {});
		},
		// 获取转诊疾病数据统计
		getimportPersonData(data) {
			let myChart = echarts.init(document.getElementById('importPerson'));
			let dataArr = [];
			for (const key in data) {
				let obj = {
					label: key,
					value: data[key],
				};
				dataArr.push(obj);
			}
			myChart.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				grid: {
					top: 30,
					left: 10,
					right: 10,
					bottom: 0,
					containLabel: true,
				},
				xAxis: {
					data: dataArr.map((item) => item.label),
					axisLine: {
						lineStyle: {
							color: 'rgba(81,81,81,0.3)',
						},
					},
					axisLabel: {
						color: '#999999',
						fontSize: 12,
						formatter: function (value, index) {
							var strs = value.split('');
							var str = '';
							for (var i = 0, s; (s = strs[i++]); ) {
								str += s;
								if (!(i % 5)) str += '\n';
							}
							return str;
						},
					},
				},
				yAxis: {
					type: 'value',
					name: '单位',
					nameTextStyle: {
						color: '#999999',
						fontSize: 12,
						align: 'right',
					},
					axisLine: {
						lineStyle: {
							color: '#fff',
						},
					},
					axisLabel: {
						color: '#999999',
						fontSize: 12,
					},
					splitLine: {
						lineStyle: {
							color: 'rgba(81,81,81,0.3)',
							type: 'dashed',
						},
					},
				},
				series: [
					{
						type: 'bar',
						barWidth: 18,
						itemStyle: {
							normal: {
								color: '#165DFF',
							},
						},
						data: dataArr.map((item) => item.value),
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取转诊成功率
		getPostChartsData(data) {
			let myChart = echarts.init(document.getElementById('waterCharts1'));
			let value = data.toFixed(2) || 0;
			myChart.setOption({
				series: [
					{
						type: 'liquidFill',
						radius: 180,
						center: ['50%', '50%'],
						data: [value, value, value],
						color: ['rgba(22,93,255,1)', 'rgba(22,93,255,.3)', 'rgba(22,93,255,.1)'],
						itemStyle: {
							shadowBlur: 0, // 波浪的阴影范围
						},
						label: {
							formatter: function (param) {
								return '{a|转诊成功比例}' + '\n' + param.value * 100 + '{b|%}';
							},
							position: ['50%', '55%'],
							fontSize: 28,

							rich: {
								a: {
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: 12,
									padding: [10, 0],
								},
								b: {
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: 12,
								},
							},
						},
						outline: {
							show: true,
							borderDistance: 0,
							itemStyle: {
								borderColor: '#165DFF',
								borderWidth: 2,
							},
						},
						backgroundStyle: {
							color: '#fff',
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.importPersonData {
	.container {
		margin: 16px;
	}
	.demo-form-inline {
		/deep/ .el-select {
			width: 106px !important;
			.el-input {
				width: 106px !important;
				.el-input__inner {
					width: 106px !important;
				}
			}
		}
		/deep/ .el-input {
			width: 106px;
		}
	}
	&-title {
		padding: 16px;
		background-color: #fff;
		border-radius: 4px;
		margin-bottom: 12px;
		&-content {
			.institutionTotal {
				display: flex;
				align-items: center;
				width: calc(50% - 6px);
				margin-right: 12px;
				background: #f9fafc;
				border-radius: 4px;
				padding: 16px;
				img {
					margin-right: 16px;
				}
				&-content {
					p {
						color: #999;
						font-size: 14px;
						height: 14px;
						line-height: 14px;
						&:last-child {
							font-size: 24px;
							line-height: 24px;
							height: 24px;
							margin-top: 12px;
							color: #333;
						}
					}
				}
			}
		}
	}
	.content {
		.chartsClass {
			width: calc(50% - 6px);
			background-color: #fff;
			margin-bottom: 12px;
			padding: 20px 16px;
			border-radius: 4px;
			&:last-child {
				margin-bottom: 0;
				margin-left: 12px;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-weight: 500;
				color: #333333;
				margin-bottom: 22px;
				display: flex;
				align-items: center;
				img {
					margin-right: 12px;
				}
			}
			.chineseMedical {
				width: calc(50% - 30px);
				height: 100%;
			}
			.legendClass {
				width: 100%;
				max-width: 270px;
				padding: 24px 32px;
				background-color: #f9fafc;
				border-radius: 4px;
				&-content {
					display: flex;
					justify-content: space-between;
					align-items: center;
					&:first-child {
						margin-bottom: 20px;
					}
					> p {
						font-size: 16px;
						line-height: 16px;
						height: 16px;
						color: #333;
						margin-bottom: 0 !important;
						span {
							font-size: 12px;
							color: #999;
						}
					}
					.legendClass-title {
						font-size: 12px !important;
						line-height: 12px;
						height: 12px;
						margin-bottom: 0;
						span {
							display: inline-block;
							width: 14px;
							height: 14px;
							border-radius: 50%;
							border: 1px solid #165dff;
							margin-right: 8px;
						}
					}
					&:last-child {
						.legendClass-title {
							span {
								background-color: #165dff;
							}
						}
					}
				}
			}
		}
	}
	.mr0 {
		margin-right: 0 !important;
	}
	.nullData {
		text-align: center;
		color: #999999;
		font-size: 20px;
	}
}
</style>